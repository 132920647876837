import { IconButton, Modal } from '@mui/material'
import { Box } from '@mui/system'
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from 'react'
import { Form } from '../../../form/Form';
import { useSelector } from 'react-redux';
import store from '../../../store';
import ListingTable from '../../../listing/listing';
import { TableSkeleton } from '../../../helper/TableSkeleton';

const FollowUpcall = ({ open, close, addedForId, addedById, listData, loading }) => {

    console.log("addedForId11", addedForId);




    var modifyTableHeaders =
        [
            { val: "notes", name: "Notes" },
            { val: "added_by_name", name: "Added By" },
            { val: "date", name: "Date", type: "datetime", format: "MM/DD/yy" },
            { val: "start_time", name: "Time" },



        ];
    let tabledataForFollowUpCall = useRef({
        tableId: "followUpCallTable",
        tableTitle: "Follow Up Calls List",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "users/follow-up-call-list-count",
            deleteSingleUserEndpoint: "",
            tableCountEndpoint: "users/follow-up-call-list-count",
        },

        reqBody: {
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
                "added_for": (addedForId !== undefined && addedForId !== null) ? addedForId : undefined
                // "added_by":(addedById !== undefined && addedById !== null) ? addedById : undefined
            },
            // "project": {},
            // "token": "",
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",

        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",

        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["name", "status", "createdon_datetime", "updatedon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [

            {
                id: "delete",
                label: "Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
                modalClassName: "delete_modal",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },


        ],
        buttons: [

            {
                id: "delete",
                label: "Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
                modalClassName: "delete_modal",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },



        ],
        deleteModal: {
            modalClassName: "modalblock",
        },


        searchData: {
            heading: "Follow Up Call Search",
            type: "drawer",
            // condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnEndIcon: "search",
                submitBtnName: "Search",
                resetBtnName: "Refresh",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 8,
                        label: "Search by Created on Start Date",
                        name: "start_date",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        className: 'list_search_item countDiv__column--col6 ',
                        payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search by Created on End Date",
                        name: "end_date",
                        className: 'list_search_item countDiv__column--col6 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    },
                ]
            }
        },
    });




    const dataform = {
        id: 'followUpcallForm',
        api_url: process.env.REACT_APP_API_URL,
        endPoint: "users/follow-up-call-add",
        // urlPathOnSuccessfulFormSubmission: "/contract-list",
        submitBtnName: "Submit",
        // cancelBtnName: "Cancel",
        className: 'inputBlock inputBlock3line',
        formButtonClass: "Formsubmit_button",
        formWrapperClass: "backend_addform addAdmin",
        formInlineClass: "backendTableInline_addform testwrpr trainingcata",

        formAdditionalSubmissionData: {

            added_for: (addedForId !== undefined && addedForId !== null) ? addedForId : undefined,
            added_by: (addedById !== undefined && addedById !== null) ? addedById : undefined,
        },

        fields: [



            {
                id: 8,
                heading: "Date ",
                label: "Plese select date here ",
                name: "date",
                className: 'inputBlock inputBlock3line',
                type: 'datePicker',
                fullDay: true,
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                sx: { m: 1, width: 300 },
                dateFormat: 'dd/MM/yyyy', //////////////////////////// MM should be capital //////////////////
                // defaultValue: new Date().getTime(),
            },
            {
                id: 10,
                heading: " Time ",
                label: "Plese select time here",
                name: "start_time",
                className: 'inputBlock inputBlock3line',
                type: 'timePicker',
                // rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                sx: { m: 1, width: 300 },
                // defaultValue: new Date().getTime(),
            },

            {
                id: 3,
                heading: "Notes",
                name: "notes",
                placeholder: "Please type here...",
                className: 'inputBlock inputBlock1line',
                type: 'textarea',
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required"
                },
                // defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.description !== undefined) ? editdata.description : undefined,

                //   minLength: "Minimum length should be 15",
                //   custom: "Value is Invalid"


            },

            // {
            //     id: 32,
            //     heading: "This is Date Time Picker",
            //     label: "Date Time Picker",
            //     name: "dateTimePicker",
            //     className: 'inputBlock inputBlock3line',
            //     type: 'dateTimePicker',
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         invalid: "Invalid Date Time Format",
            //         custom: "Value is Invalid"
            //     },
            //     sx: { m: 1, width: 300 },
            //     defaultValue: new Date().getTime(), 
            // },


        ],
        customButtons: [
            {
                id: 'goback001',
                label: 'Go Back',
                className: 'default',
                type: 'button',
            },
        ]

    }



    return (
        <>


            <div>
                <Modal
                    classname="image_card_modal modalBaseStyle "
                    open={open}
                    onClose={close}
                    closeAfterTransition
                >
                    <Box className='modalBaseBox largeModal followup_container'>
                        <IconButton className="modal_icon_button" onClick={close}>
                            <CloseIcon className="modal_close_icon" />
                        </IconButton>


                        <div className='followup_wrap'>
                            <div className='rolelist_mainwrp_Table'>

                                {!loading ? (
                                    <ListingTable

                                        tableData={tabledataForFollowUpCall.current}
                                        dataset={listData}
                                        modifyHeaders={modifyTableHeaders}
                                    />
                                ) : (<TableSkeleton count={5} />)}

                            </div>






                            <h1 className='page_heading'>Add a Follow Up call </h1>


                            <div className="dataform2_wrapper">
                                {/* {loading == true ? <LinearProgress /> : false} */}
                                <div className='adminformbody'>
                                    <Form formData={dataform} />

                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>





        </>



    )
}

export default FollowUpcall