import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import MovingIcon from '@mui/icons-material/Moving';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ArticleIcon from '@mui/icons-material/Article';
import DnsIcon from '@mui/icons-material/Dns';
import LayersIcon from '@mui/icons-material/Layers';
import GroupsIcon from '@mui/icons-material/Groups';
import IosShareIcon from '@mui/icons-material/IosShare';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import EditIcon from '@mui/icons-material/Edit';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import LockIcon from '@mui/icons-material/Lock';
import PreviewIcon from '@mui/icons-material/Preview';
import InfoIcon from '@mui/icons-material/Info';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import { useDispatch, useSelector } from "react-redux";
import { setEnableRowLoader } from "../../../listing/listReducer";
import { fetchContract, FetchHMSettingModal, FetchStages, FetchTagDetails, FetchTrainingStatus, fetchUserRoles, logInInfo, rolesForUpgrade, roundRobin, sendResetPassword, userManagementResendWelcomeEmail } from "../userManagement/userList/userListReducer";
import UpgradeRoleModal from "../userManagement/userList/upgradeRoleModal";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { getDatafollowUpCallList, gettagalldata, ShowStageList } from "../LeadManagement/LeadReducer";
import { folderViewVisitLog } from "../userManagement/folderView/folderViewReducer";
import { resolvefunction } from "../../../helper/helperFunctions";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { fetchUserData } from "../userManagement/fetchUserDataReducer";
import SummarizeIcon from '@mui/icons-material/Summarize';
import FolderIcon from '@mui/icons-material/Folder';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StreetviewIcon from '@mui/icons-material/Streetview';
import { Undo } from "@mui/icons-material";
import { getInformationListData } from "../InformationlinkModule/InformationReducer";
import { getEmailTeamplatedata } from "../EmailTemplateManagement/EmailTemplateReducer";


function BtnsComponent({ 
  dataRef, 
  setGridSingelUser,
  openModal, 
  fromList, 
  rowgrid, 
  setContractPreviewModal ,
  setStatusBtnClick,
  fromGrid,
  userInfoData,
  setshowtagListModal,
  setshowstageList,
  setOpenDrowerForInfoLink,
  setMultiStatusModal,
  setStageModal,
  setUpgradeRoleModal}) {
  const [dataCopy, setDataCopy] = useState({}); //--- dataCopy  ----//
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [fatchabelData,setFatchabelData]=useState({})

  const [UserId, setUserId] = useState(null);
  const [btnType, setBtnType] = useState(""); //--- ButtonType Set  ----//

  const [snackFire, setSnackFire] = useState(false); //--- Open SnackBar ----//
  const [resMessage, setResMessage] = useState(null); //--- resMessage  ----//
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const editData = useSelector((state) => state.tableSlice?.tempRowData?.leaddata ? state.tableSlice.tempRowData.leaddata : {});
//----------ShwoStageLife Cycle Data------------------------

const fetchStagelistData = useSelector(state => (state.LeadReducer?.ShowStageListData) ? state.LeadReducer.ShowStageListData : []) // ------- FetchedStagelistData
//----------ShwoTagLife Cycle Data------------------------

let taglifecycledatas = useSelector((state) => state.LeadReducer?.taglifecycledata && state.LeadReducer?.taglifecycledata.length > 0 ? state.LeadReducer?.taglifecycledata : []);  







//---------------- Macking CommonData For Fetching -------------------------
useEffect(()=>{
  if(dataRef !== undefined){
    if(Object.keys(dataRef).length>0){
      console.log("yes data ref is avalebel   gggdd1234");
      setFatchabelData(dataRef)
    }
  }
  else if(rowgrid !== undefined){
    if(Object.keys(rowgrid).length>0){
      console.log("grid data is avalebel  gggdd1234");
      setGridSingelUser(rowgrid)
      setFatchabelData(rowgrid)

    }
  } 
},[dataRef,rowgrid])





// ---------------------------------------upgrade roles----------------------------------
const upgradeRole = () => {
  if(fromGrid !== undefined){
    dispatch(rolesForUpgrade(rowgrid))
  }else if(fromList !== undefined){
    dispatch(rolesForUpgrade(fatchabelData))
  }
  setUpgradeRoleModal(true)
}




  // ---------------------------------------ManageStage -----------------------------------------------------
const ManageStage = () => {
    dispatch(setEnableRowLoader({tableId: "leaddata",rowId:fatchabelData._id,load:true}));
    const data = {
      condition: {},
      sort: {},
      searchcondition: { added_for:fatchabelData._id, roles: { $in: ["is_lead"] } },
      project: { subject: 1 },
      token: "",
      count: false,
      };
    dispatch(FetchStages(data));
  }




  // ---------------------------------- send Contract function ----------------------------------------
  const sendContracet = () => {
     
      const data = {
        "condition": {},
        "sort": {
          "field": "name",
          "type": "asc"
        },
        "searchcondition": {
          "status": 1,
          "rep_only": 1
        },
        "project": { "name": 1 },
        "token": "",
        "data": { "user_id":fatchabelData._id }
      }
      dispatch(fetchContract(data))
  }




  // Manage Hm Sating Function 

  const ManageHmSatting = () => {
    const data = {
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "field": "name",
        "type": "asc"
      },
      "searchcondition": {
        "_id": fatchabelData._id
      },
      "count": false
    }
    dispatch(FetchHMSettingModal(data))
  }





  const ManageTag = () => {
    let roles = Object.keys(fatchabelData?.roles).map((key) => key)


    const data = {
      "_id": fatchabelData._id,
      "roles": { $in: roles },
      "project": {
        "subject": 1
      },
    }

    dispatch(FetchTagDetails(data))
  }









  const manageRoundRobin=()=>{
    const data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        field: "name",
        type: "asc",
      },
      searchcondition: {
        _id: fatchabelData._id,
      },
      count: false,
    };

    dispatch(roundRobin(data));

  }

  const manageFollowUp=()=>{
    dispatch(
      setEnableRowLoader({
        tableId: "userslist1",
        rowId: fatchabelData._id,
        load: true,
      })
    ); //---- row loadder ----

    setDataCopy(fatchabelData);

    let reqbody = {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {
        added_for: fatchabelData._id,
      },
      // "project": {},
      // "token": "",
      count: false,
      source: "",
    };

    dispatch(getDatafollowUpCallList(reqbody));
  }

  const manageTraininStatus=()=>{
    const data = {
      source: "lesson_list",
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {
        user_id: fatchabelData._id,
      },
      project: {},
      token: "",
      email: "",
    };

    dispatch(FetchTrainingStatus(data));

  }

  const manageFolderView=()=>{
    setDataCopy(fatchabelData);
      // dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: true })) //---- row loadder true----

      const payload = {
        user_for: fatchabelData._id,
        user_by: userInfo?.userinfo._id,
      };
      dispatch(folderViewVisitLog(payload));
      resolvefunction(navigate, "/folderview?id=" + fatchabelData._id);

  }

  const manageAssignRole=()=>{
    dispatch(fetchUserRoles(fatchabelData));
      setUserId(fatchabelData._id);

  }

  const manageEdit=()=>{
    resolvefunction(navigate, "/useraddedit?id=" + fatchabelData._id);
  }

  const manageResendMail=()=>{
    setBtnType("welcomeMailBtn");
      setDataCopy(fatchabelData);
      dispatch(userManagementResendWelcomeEmail(fatchabelData)); //-- fetchUserData endpoint call from this func --
  }

  const manageChangeStatus=()=>{
   
    setMultiStatusModal(true)
    
  }

  const manageLoginInfo=()=>{
    setDataCopy(fatchabelData);
    setSnackFire(false);
    setResMessage(null);

    dispatch(logInInfo(fatchabelData));

  }

  const managePreview=()=>{
    setDataCopy(fatchabelData);
    // dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: fatchabelData._id, load: true })) //---- row loadder ----
    dispatch(fetchUserData(fatchabelData._id)); //-- fetchUserData endpoint call from this func --

  }

  const manageResetPassword=()=>{
      setBtnType("sendResetPassword");
      setDataCopy(fatchabelData);
      dispatch(sendResetPassword(fatchabelData)); //-- fetchUserData endpoint call from this func --
    

  }

  

  const callstagefetchapi = async (val) => {
    const data = {
      "_id": val
    }
    await dispatch(ShowStageList(data))

    dispatch(
      setEnableRowLoader({
        tableId: "leaddata",
        rowId: fetchStagelistData._id,
        load: false,
      })
    )
    setshowstageList(true)
  }


  const manageStageLifecycle=()=>{
    callstagefetchapi(fetchStagelistData._id)

      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: fetchStagelistData._id,
          load: true,
        })
      )
  
  
  }

  const calltagfetchapi = async (val) => {
    const data = {
      _id: val,
    };

    await dispatch(gettagalldata(data));

    dispatch(
      setEnableRowLoader({
        tableId: "leaddata",
        rowId: taglifecycledatas._id,
        load: false,
      })
    )

    setshowtagListModal(true);
    
  };

  const manageTagLifecycle=()=>{
    calltagfetchapi(taglifecycledatas._id);

      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: taglifecycledatas._id,
          load: true,
        }))

  }

  const manageSendInformationLink=()=>{

    dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: true }));

    let reqbody = {
      condition: { limit: 5, skip: 0 },
      sort: { field: "_id", type: "desc" },
      searchcondition: {
        // "added_for": addedForId
      },
      count: false,
      source: "",
    };

    dispatch(getInformationListData(reqbody));
    
      setOpenDrowerForInfoLink(true);
    

  }


  const goFolderview=()=>{
    const payload = {
      user_for: rowgrid._id,
      user_by: userInfoData?.userinfo._id,
    };
    dispatch(folderViewVisitLog(payload));
    // resolvefunction(navigate, "/folderview?id=" + fatchabelData._id)
    window.open(`/folderview?id=${rowgrid._id}`, "_blank");
  }
  const goLeade=()=>{
    window.open(`/lead-list/${rowgrid.roles}/${rowgrid._id}`, "_blank");
  }

  const manageIpInfo=()=>{
    dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: true }));

  }

  const manageSendMail=()=>{
    dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: true }))  // Start Row Loader

      let reqbody = {
        condition: { limit: 5, skip: 0 },
        sort: { field: "_id", type: "desc" },
        searchcondition: {
          // "added_for": addedForId
          "roles": { $in: ["is_lead"] }
        },
        count: false,
        source: "",
      };

      dispatch(getEmailTeamplatedata(reqbody));

  }


  return (
    <>
      {fromList ? "" : (
        <>
          <Tooltip title="Notes">
            <IconButton onClick={() => openModal()}>
              <SummarizeIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Folder View">
            <IconButton onClick={() => goFolderview()}>
              <FolderIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Training Report">
            <IconButton onClick={() => openModal()}>
              <AssessmentIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Leads">
            <IconButton onClick={() => goLeade()}>
              <StreetviewIcon />
            </IconButton>
          </Tooltip> </>
      )

      }




      <Tooltip title="Upgrade Role">
        <IconButton onClick={() => upgradeRole()}>
          <MovingIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Manage Stage">
        <IconButton onClick={() => ManageStage()}>
          <ClearAllIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Send Contract">
        <IconButton onClick={() => sendContracet()}>
          <ArticleIcon />
        </IconButton>
      </Tooltip>


      <Tooltip title="Tag Manage">
        <IconButton onClick={() => ManageTag()}>
          <LocalOfferIcon />
        </IconButton>
      </Tooltip>

      {(fatchabelData?.main_role === "hr_manager") && (<Tooltip title="Manage HM Settings">
        <IconButton onClick={() => ManageHmSatting()}>
          <LayersIcon />
        </IconButton>
      </Tooltip>)}



      <Tooltip title="Manage Round Robin">
        <IconButton onClick={() => manageRoundRobin()}>
          <GroupsIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Followup Call">
        <IconButton onClick={() => manageFollowUp()}>
          <FollowTheSignsIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Training Status">
        <IconButton onClick={() => manageTraininStatus()}>
          <ModelTrainingIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Assign User Role">
        <IconButton onClick={() => manageAssignRole()}>
          <SettingsSuggestIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Folder View">
        <IconButton onClick={() => manageFolderView()}>
          <FolderCopyIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Edit">
        <IconButton onClick={() => manageEdit()}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Resend Welcome Mail">
        <IconButton onClick={() => manageResendMail()}>
          <MarkunreadIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Change Status">
        <IconButton onClick={() => manageChangeStatus()}>
          <ToggleOffIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Send Reset Password">
        <IconButton onClick={() => manageResetPassword()}>
          <LockIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="preview">
        <IconButton onClick={() => managePreview()}>
          <PreviewIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Login Info">
        <IconButton onClick={() => manageLoginInfo()}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
   

    { fromList && <Tooltip title="Stage Lifecycle">
        <IconButton onClick={() => manageStageLifecycle()}>
          <ViewDayIcon/>
        </IconButton>
      </Tooltip>}

     {fromList && <Tooltip title="Tag Lifecycle">
        <IconButton onClick={() => manageTagLifecycle()}>
          <DataSaverOffIcon/>
        </IconButton>
      </Tooltip>}


      {fromList && <Tooltip title="Send Information Link">
        <IconButton onClick={() => manageSendInformationLink()}>
          <IosShareIcon/>
        </IconButton>
      </Tooltip>}

      {fromList && <Tooltip title="Registration IP Info">
        <IconButton onClick={() => manageIpInfo()}>
          < DnsIcon/>
        </IconButton>
      </Tooltip>}

      {fromList && <Tooltip title="Send Email">
        <IconButton onClick={() => manageSendMail()}>
          < ForwardToInboxIcon/>
        </IconButton>
      </Tooltip>}


      
      
    </>
  );
}

export default BtnsComponent;
