
import { IconButton, LinearProgress, Tooltip } from '@mui/material'
import React, { useEffect, useState, useLocation } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import FDSearch from './FDSearch';
import { UserActivity } from './folderViewReducer';
import FDNotes from './FDNotes';

const Notesview = ({ notesData }) => {
  // ---------------------------------------------Invoking Hooks--------------------------------------------- //
  const dispatch = useDispatch()

  // ----------------------------------------Global State from Redux---------------------------------------- //
  const loading = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)
  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)
  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})
  const noteData = useSelector(state => (state?.userFolderViewReducer?.notesData) ? state.userFolderViewReducer.notesData : null)

  // ----------------------------------------Components Private State---------------------------------------- //
  const [searchDraw, setSearchDraw] = useState(false); // snackbar const  
  const [notesModel, setNotesModel] = useState(false); // notemodel state
  const [queryParamId, setQueryParamId] = useState();

  // ---- geeting and setting QueryParam -----
  // const queryParam = useLocation();
  // const paramId = queryParam.search.split("=");

  // useEffect(() => {
  //   if (paramId[1]) {
  //     setQueryParamId(paramId[1])
  //   }
  // }, [JSON.stringify(paramId)])
  // -------------------------------

  // --------------------------------------------Other Veriables-------------------------------------------- //
  console.log("notes", notesData);
  const searchFields = [
    {
      id: 8,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 600 },
      dateFormat: 'dd/MM/yyyy',


    },
    {
      id: 9,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 600 },
      dateFormat: 'dd/MM/yyyy',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]

  // -------------------------------------------useEffect Section------------------------------------------- //
  useEffect(() => {
    if (submissionState === 4) {
      setSearchDraw(false)
      let activity_time = {
        "$gte": searchData?.start_date ? searchData.start_date : undefined,
        "$lte": searchData?.end_date ? searchData.end_date : undefined
      }

      activity_time.$lte !== " " || activity_time.$lte !== undefined && delete activity_time["$lte"];
      activity_time.$gte !== " " || activity_time.$gte !== undefined && delete activity_time["$gte"];

      const payload = {
        "condition": {
          "limit": 5,
          "skip": 0
        },
        "sort": {
          "field": "activity_time",
          "type": "desc"
        },
        "searchcondition": {
          "user_id": queryParamId ? queryParamId : undefined,
          "activity_time": activity_time
        },
        "project": {},
        "token": "",
        "email": ""
      }
      dispatch(UserActivity(payload))
    }
  }, [submissionState])

  // -------------------------------------------Other Handlers------------------------------------------- //
  const closeModal = () => setNotesModel(false)

  //----------------------------------------------return-----------------------------------------------//
  return (
    <>
      <div>
        <h3>
          <SummarizeIcon /> Notes {" "}
          <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
            <Tooltip title="Search Notesview">
              <IconButton onClick={() => setSearchDraw(true)}><SearchIcon /></IconButton></Tooltip>
            <Tooltip title="Add Notes"><IconButton onClick={() => setNotesModel(true)}><AddIcon /></IconButton></Tooltip>
          </div>
        </h3>

        {loading ? <Box sx={{ width: "100%", marginBottom: "10px" }}><LinearProgress /></Box> : null}
        {
          notesData.length > 0 ?
            notesData.map((item) => {
              return (
                <div className="user-activity" key={item._id}>
                  <ul className="ua-data-block">
                    <li>Notes: {item.notes} </li>
                    <li>Added by: {item.added_name} </li>
                    <li>Added On: {format(item.createdon_datetime, "dd-MM-yyyy HH:mm:ss")} </li>
                  </ul>
                </div>
              )
            }) : <p>No activity found!</p>
        }
      </div>

      {searchDraw && <FDSearch open={searchDraw} close={setSearchDraw} formFields={searchFields} searchFields={searchFields} type="notes_view " />}
      {notesModel && <FDNotes openMoadl={notesModel} closeModal={closeModal} noteData={noteData} />}
    </>
  )
}

export default Notesview